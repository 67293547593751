import React from "react";
import "./WaveLineUp.css";
const WaveLineUp = () => (
    <div className="WaveLineUp-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
                fill="#61c4ff"
                fillOpacity="1"
                d="M0,96L20,112C40,128,80,160,120,149.3C160,139,200,85,240,90.7C280,96,320,160,360,176C400,192,440,160,480,154.7C520,149,560,171,600,160C640,149,680,107,720,112C760,117,800,171,840,165.3C880,160,920,96,960,96C1000,96,1040,160,1080,160C1120,160,1160,96,1200,64C1240,32,1280,32,1320,53.3C1360,75,1400,117,1420,138.7L1440,160L1440,0L1420,0C1400,0,1360,0,1320,0C1280,0,1240,0,1200,0C1160,0,1120,0,1080,0C1040,0,1000,0,960,0C920,0,880,0,840,0C800,0,760,0,720,0C680,0,640,0,600,0C560,0,520,0,480,0C440,0,400,0,360,0C320,0,280,0,240,0C200,0,160,0,120,0C80,0,40,0,20,0L0,0Z"
                style={{ "--darkreader-inline-fill": "#007acc" }}
            />
        </svg>
    </div>
);

export default WaveLineUp;
